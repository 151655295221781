import { api } from "../../../api/api";
import { UserResponse } from "./types";

const LIST_USERS_TAG = "listUsers";

export const usersApi = api.enhanceEndpoints({ addTagTypes: [LIST_USERS_TAG] }).injectEndpoints({
    endpoints: build => ({
        getAllUsers: build.query<
            UserResponse,
            { search?: string; country?: number[]; company__is_developer?: boolean; company__is_investor?: boolean }
        >({
            query: ({ search, country, company__is_developer, company__is_investor }) => ({
                method: "GET",
                url: "users/list",
                params: { search, country, company__is_developer, company__is_investor }
            }),
            providesTags: [LIST_USERS_TAG]
        })
    })
});

export const { useGetAllUsersQuery } = usersApi;
