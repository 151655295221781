import { Box, Button, Flex, HStack, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack, useTheme } from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { CheckIcon } from "../../../assets/icons/check";
import { FanIcon } from "../../../assets/icons/fan";
import { BiohazardIcon } from "../../../assets/svg/biohazard";
import { SunIcon } from "../../../assets/svg/sun";
import { WaveIcon } from "../../../assets/svg/wave";
import { WindIcon } from "../../../assets/svg/wind";
import { getCountryFlag } from "../../../utils/flags";
import { ButtonToChat } from "../../assistant/components/ButtonToChat";
import { ChatBot } from "../../assistant/components/ChatBot";
import { EnergyTypeText } from "../../projects/api/types";
import { EnergyBadge } from "../../projects/components/EnergyBadge";
import { ProjectSuggestionsRow } from "../../projects/components/ProjectSuggestionsRow";
import { MessageTypes, sendToWs } from "../../websocket/api/socketApi";
import { useGetCountryDetailsQuery } from "../api/exploreApi";
import { Arc } from "../components/charts/Arc";
import { HorizontalBar } from "../components/charts/HorizontalBar";
import { LineChart } from "../components/charts/Line";
import { PieChart } from "../components/charts/Pie";

export const CountryDetails = () => {
    const { colors } = useTheme();

    const { countryCode = "" } = useParams<{ countryCode: string }>();
    const { data, isLoading } = useGetCountryDetailsQuery({ countryCode });

    const [isAssistantVisible, setIsAssistantVisible] = useState(false);

    if (isLoading || !data?.gdp_data) return <Spinner />;

    const dataArc = (data: number[]) => ({
        labels: ["80", "20"],
        datasets: [
            {
                label: "%",
                data,
                backgroundColor: [colors.green[400], colors.gray[300]],
                borderColor: [colors.green[400], colors.gray[300]],
                borderWidth: 1,
                order: 1
            }
        ]
    });

    const arcsInfo = [
        {
            Label: (
                <VStack gap='0'>
                    <Text fontSize='xs' fontWeight='bold'>
                        Compliance
                    </Text>
                    <Text fontSize='xs'>Easy</Text>
                </VStack>
            ),
            data: dataArc([80, 20]),
            icon: <CheckIcon />
        },
        {
            Label: (
                <VStack gap='0'>
                    <Text fontSize='xs' fontWeight='bold'>
                        Carbon emission:
                    </Text>
                    <Text fontSize='xs'>Low</Text>
                </VStack>
            ),
            data: dataArc([20, 80]),
            icon: <FanIcon />
        },
        {
            Label: (
                <VStack gap='0'>
                    <Text fontSize='xs' fontWeight='bold'>
                        Decarbonization goal:
                    </Text>
                    <Text fontSize='xs'>0 by 2030</Text>
                </VStack>
            ),
            data: dataArc([30, 70]),
            icon: <FanIcon />
        }
    ];

    const startChat = (userId: string) => {
        sendToWs({
            msg_type: MessageTypes.DialogCreated,
            recipient_id: userId
        });
    };

    return (
        <HStack bg='white' p='1rem' borderRadius='lg' w='full' gap='5rem' alignItems='flex-start'>
            <VStack w='40%'>
                <HStack w='full' alignItems='flex-start' pb='2rem'>
                    <VStack flexGrow='1' gap='.5rem' color='gray.600'>
                        <Text fontWeight='bold' fontSize='1.8rem' p='0' w='full'>
                            {data?.country.name}
                        </Text>
                        <Text p='0' w='full' fontSize='small'>
                            Capital: {data?.country.capital}
                        </Text>
                        <Text p='0' w='full' fontSize='small'>
                            Size: {data?.country.area.toLocaleString()} km²
                        </Text>
                    </VStack>

                    <Box
                        w='13rem'
                        h='8.2rem'
                        backgroundSize='cover'
                        backgroundPosition='center'
                        backgroundImage={`url(${getCountryFlag(countryCode)})`}
                        borderRadius='xl'
                        borderWidth='1px'
                        borderColor='black'
                    />
                </HStack>
                <HStack mb='2rem'>
                    <PieChart labels={["83% Renewable Energy", "17% Non-renewable Energy"]} data={[83, 17]} labelPosition='bottom' />
                </HStack>
                <VStack w='full' gap='2rem'>
                    <VStack w='full' alignItems='flex-start'>
                        <HStack gap='.2rem'>
                            <Text fontWeight='bold' fontSize='xs'>
                                Efficiency:
                            </Text>
                            <Text fontSize='xs'>80%</Text>
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Sun' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                    </VStack>
                    <VStack w='full' alignItems='flex-start'>
                        <HStack gap='.2rem'>
                            <Text fontWeight='bold' fontSize='xs'>
                                Penetration:
                            </Text>
                            <Text fontSize='xs'>60%</Text>
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Sun' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Wind' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Biomass' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Water' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                    </VStack>
                    <VStack w='full' alignItems='flex-start'>
                        <HStack gap='.2rem'>
                            <Text fontWeight='bold' fontSize='xs'>
                                Installed capacity:
                            </Text>
                            <Text fontSize='xs'>200MW</Text>
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Sun' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Wind' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Biomass' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Water' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                    </VStack>
                    <VStack w='full' alignItems='flex-start'>
                        <HStack gap='.2rem'>
                            <Text fontWeight='bold' fontSize='xs'>
                                Energy consumption:
                            </Text>
                            <Text fontSize='xs'>800kWh</Text>
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Sun' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Wind' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Biomass' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                        <HStack gap='1rem' w='full'>
                            <IconComponent name='Water' />
                            <HorizontalBar value='80%' min='0' max='100%' />
                        </HStack>
                    </VStack>
                </VStack>
            </VStack>
            <VStack w='60%' justifyContent='flex-start' gap='3rem'>
                <HStack gap='1rem'>
                    {arcsInfo.map((arc, index) => (
                        <Box position='relative' borderWidth='1px' p='2' py='0' borderRadius='lg' key={JSON.stringify(arc.data.labels)}>
                            <Box position='absolute' top='5'>
                                {arc.icon}
                            </Box>
                            <Arc data={arc.data} size={200} label={arc.Label} />
                        </Box>
                    ))}
                </HStack>
                <LineChart data={data?.gdp_data} />
                <VStack w='full' alignItems='flex-start' gap='1rem'>
                    <Text fontWeight='bold' fontSize='sm'>
                        Local developers:
                    </Text>
                    {data.developers.length > 0 ? (
                        <TableContainer w='full'>
                            <Table variant='simple'>
                                <Thead bg={colors.gray[100]}>
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th>Company</Th>
                                        <Th>E-mail</Th>
                                        <Th>Chat</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {data.developers.map(developer => (
                                        <Tr fontSize='sm' key={developer.id}>
                                            <Td>{developer.first_name + " " + developer.last_name}</Td>
                                            <Td>{developer.company.name}</Td>
                                            <Td>{developer.email}</Td>
                                            <Td>
                                                <Button size='xs' onClick={() => startChat(String(developer.id))}>
                                                    Chat
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}
                                    {/* {dummyDevelopers.map(developer => (
                                    <Tr fontSize='sm' key={developer.name}>
                                        <Td>{developer.name}</Td>
                                        <Td>{developer.location}</Td>
                                        <Td>
                                            <Box display='flex' justifyContent='flex-start' alignItems='center'>
                                                <EnergyBadge energyType={developer.industry} />
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Button size='xs'>Chat</Button>
                                        </Td>
                                    </Tr>
                                ))} */}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Text fontWeight='bold'>No developers in {data.country.name} yet.</Text>
                    )}
                </VStack>
                <VStack w='full' alignItems='flex-start' gap='1rem'>
                    <Text fontWeight='bold' fontSize='sm'>
                        Projects:
                    </Text>
                    <Flex gap='.5rem' w='full' alignItems='flex-start' overflowX='auto' direction={{ base: "column", md: "row" }}>
                        {data.projects.length > 0 ? (
                            data.projects.map(project => <ProjectSuggestionsRow key={project.slug} project={project} />)
                        ) : (
                            <Text fontWeight='bold'>No projects in {data.country.name} yet.</Text>
                        )}
                    </Flex>
                </VStack>
            </VStack>
            <ChatBot countryCode={countryCode} isVisible={isAssistantVisible} onClose={() => setIsAssistantVisible(false)} />
            <ButtonToChat onClick={() => setIsAssistantVisible(!isAssistantVisible)} />
        </HStack>
    );
};

type IconComponentType = "Sun" | "Wind" | "Biomass" | "Water";

const IconComponent = ({ name }: { name: IconComponentType }) => {
    const { colors } = useTheme();

    const iconsData: Record<IconComponentType, JSX.Element> = {
        Biomass: <BiohazardIcon color={colors.green[500]} />,
        Sun: <SunIcon color={colors.green[500]} />,
        Water: <WaveIcon color={colors.green[500]} />,
        Wind: <WindIcon color={colors.green[500]} />
    };
    const Icon = iconsData[name];
    return (
        <HStack w='6rem'>
            {Icon}
            <Text fontSize='xs'>{name}</Text>
        </HStack>
    );
};
