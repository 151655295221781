import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from "@chakra-ui/react";

import { CommodityType } from "../../commodities/api/types";
import { CommodityCard } from "../../commodities/components/CommodityCard";

type RightPanelProps = {
    commodities?: CommodityType[];
};

export const RightPanel = ({ commodities }: RightPanelProps) => {
    return (
        <VStack alignSelf='flex-start' w={{ base: "100%", md: "20%" }}>
            <Tabs variant='soft-rounded' colorScheme='green' size='sm' w='full'>
                <TabList fontSize='xs'>
                    <Tab>Commodities</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel px='0'>
                        <VStack spacing='1rem' bg='white' borderRadius='xl' p='3'>
                            {commodities && commodities.map(commodity => <CommodityCard key={commodity.id} commodity={commodity} />)}
                        </VStack>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    );
};
