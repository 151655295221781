import { Button, Flex, HStack, Input, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { CustomModal } from "../../../components/CustomModal";
import { GridCards } from "../../../components/GridCards";
import { PageLayout } from "../../../components/PageLayout";
import { useGetUserDetailsQuery } from "../../home/api/usersApi";
import { NBOList } from "../../portfolio/components/NBOList";
import { UserType } from "../../users/api/types";
import {
    useCreatePortfolioMutation,
    useGetOwnPortfoliosListQuery,
    useGetOwnProjectsListQuery,
    useGetPendingNDAsQuery,
    useGetSentNDAsQuery,
    useLazyRejectNDARequestQuery,
    useLazySendNDARequestQuery
} from "../api/projectsApi";
import { PortfolioCard } from "../components/PortfolioCard";
import { ProjectCard } from "../components/ProjectCard";

export const MyHub = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onAddPortfolio = () => {
        onOpen();
    };

    const { data: user } = useGetUserDetailsQuery();
    const { data: projects, isLoading: loadingProjects } = useGetOwnProjectsListQuery();
    const { data: portfolios, isLoading: loadingPortfolios } = useGetOwnPortfoliosListQuery();
    // NDA's
    const { data: pendingNdas } = useGetPendingNDAsQuery();
    const { data: sentNdas } = useGetSentNDAsQuery();
    const [rejectNdaRequest, { isLoading: loadingRjectNda }] = useLazyRejectNDARequestQuery();
    const [sendNdaRequest, { isLoading: loadingSendNda }] = useLazySendNDARequestQuery();

    const [create, { isLoading: loadingCreatePortfolio }] = useCreatePortfolioMutation();
    const [newPortfolio, setNewPortfolio] = useState({
        name: "",
        description: ""
    });

    if (loadingProjects || loadingPortfolios) {
        return <Spinner />;
    }

    const createPortfolio = async () => {
        try {
            await create(newPortfolio).unwrap();
        } catch (err) {
            console.error(err);
        }
        onClose();
    };

    return (
        <PageLayout title='My projects' rightAction={<CreateButtons user={user} onAddPortfolio={onAddPortfolio} />}>
            <Tabs variant='soft-rounded' colorScheme='green' size='sm' w='100%' h='full'>
                <TabList>
                    <Tab>Projects</Tab>
                    <Tab>Portfolios</Tab>
                    <Tab>NDA's</Tab>
                    <Tab>NBO's</Tab>
                </TabList>
                <TabPanels h='full' overflowY='auto' bg='white' mt='1rem' borderRadius='lg'>
                    <TabPanel overflow='auto'>
                        <VStack spacing='1rem' width='full' alignItems='flex-start'>
                            <HStack w='full'>
                                {projects && projects?.results.length > 0 ? (
                                    <GridCards>{projects?.results.map(project => <ProjectCard key={project.id} project={project} />)}</GridCards>
                                ) : (
                                    <Flex alignItems='center' justifyContent='center' w='full' h='80vh'>
                                        <VStack gap='1rem'>
                                            <Text fontWeight='bold' fontSize='3xl'>
                                                No projects yet
                                            </Text>
                                            <Button as={Link} to='/project/create'>
                                                Create new project
                                            </Button>
                                        </VStack>
                                    </Flex>
                                )}
                            </HStack>
                        </VStack>
                    </TabPanel>
                    <TabPanel>
                        <HStack w='full'>
                            <GridCards>{portfolios?.results.map(portfolio => <PortfolioCard key={portfolio.id} portfolio={portfolio} />)}</GridCards>
                        </HStack>
                    </TabPanel>
                    <TabPanel>
                        <VStack h='full' w='100%'>
                            <Tabs variant='enclosed' colorScheme='green' size='sm' h='full' w='full'>
                                <TabList>
                                    <Tab fontSize='xs'>Requested NDA's</Tab>
                                    <Tab fontSize='xs'>Awaiting for signature</Tab>
                                    <Tab fontSize='xs'>Accepted NDA's</Tab>
                                </TabList>
                                <TabPanels h='full' overflowY='auto' bg='white' mt='1rem' borderRadius='lg'>
                                    <TabPanel>
                                        <HStack w='full' gap='1rem' flexWrap='wrap'>
                                            {pendingNdas?.count === 0 ? (
                                                <Text>No NDA's</Text>
                                            ) : (
                                                pendingNdas?.results.map(nda => (
                                                    <VStack key={nda.id} borderWidth='1px' borderColor='gray.200' shadow='lg' borderRadius='md' p='2'>
                                                        <Text fontSize='sm'>
                                                            {nda.user.name} requested access to NDA for project{" "}
                                                            <Link to={`/projects/${nda.project.slug}`}>{nda.project.name}</Link>
                                                        </Text>
                                                        <HStack gap='2' w='full'>
                                                            <Button
                                                                size='xs'
                                                                variant='ghost'
                                                                isLoading={loadingSendNda}
                                                                onClick={() => sendNdaRequest({ ndaId: nda.id })}>
                                                                Send NDA document
                                                            </Button>
                                                            <Button
                                                                size='xs'
                                                                variant='ghost'
                                                                colorScheme='red'
                                                                isLoading={loadingRjectNda}
                                                                onClick={() => rejectNdaRequest({ ndaId: nda.id })}>
                                                                Reject
                                                            </Button>
                                                        </HStack>
                                                    </VStack>
                                                ))
                                            )}
                                        </HStack>
                                    </TabPanel>
                                    <TabPanel>
                                        <HStack w='full' gap='1rem' flexWrap='wrap'>
                                            {sentNdas?.count === 0 ? (
                                                <Text>No NDA's</Text>
                                            ) : (
                                                sentNdas?.results.map(nda => (
                                                    <VStack key={nda.id} borderWidth='1px' borderColor='gray.200' shadow='lg' borderRadius='md' p='2'>
                                                        <Text fontSize='sm'>
                                                            {nda.user.name} requested access to NDA for project{" "}
                                                            <Link to={`/projects/${nda.project.slug}`}>{nda.project.name}</Link>
                                                        </Text>
                                                    </VStack>
                                                ))
                                            )}
                                        </HStack>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </VStack>
                    </TabPanel>
                    <TabPanel>
                        <NBOList />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <CustomModal
                isOpen={isOpen}
                onClose={onClose}
                title='Create a new portfolio'
                confirmText='Create'
                onConfirm={createPortfolio}
                isLoading={loadingCreatePortfolio}>
                <VStack spacing='1.5rem'>
                    <Input
                        value={newPortfolio.name}
                        placeholder='Name'
                        onChange={e => {
                            setNewPortfolio({ ...newPortfolio, name: e.target.value });
                        }}
                    />
                    <Input
                        value={newPortfolio.description}
                        placeholder='Description'
                        onChange={e => {
                            setNewPortfolio({
                                ...newPortfolio,
                                description: e.target.value
                            });
                        }}
                    />
                </VStack>
            </CustomModal>
        </PageLayout>
    );
};

type CreateButtonsProps = {
    onAddPortfolio: () => void;
    user?: UserType;
};

const CreateButtons = ({ user, onAddPortfolio }: CreateButtonsProps) => {
    return (
        <HStack spacing='4'>
            <Button as={Link} to='/project/create' colorScheme='green' size='sm'>
                Create project
            </Button>
            <Button colorScheme='green' size='sm' onClick={onAddPortfolio}>
                Create portfolio
            </Button>
        </HStack>
    );
};
