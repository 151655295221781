import { MultiSelect, Option, SelectionVisibilityMode } from "chakra-multiselect";
import { useState } from "react";

import { useGetCountriesQuery } from "../modules/geography/api/geographyApi";

export const useCountrySelect = (defaultValue?: number, placeholder?: string) => {
    const { data } = useGetCountriesQuery();
    const [value, setValue] = useState<string | undefined>(defaultValue ? String(defaultValue) : "");

    const options = data?.map(({ id, name }) => ({ label: name, value: String(id) }));

    const CountrySelect = (
        <MultiSelect
            placeholder={placeholder || "Select country"}
            options={options || []}
            value={value}
            onChange={val => {
                // @ts-ignore
                setValue(val);
            }}
            size='md'
            single
            filterFn={(option, query) => {
                const searchValue = query as string;
                return option.filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()));
            }}
        />
    );

    return { CountrySelect, value: Number(value) };
};

export const useMultiCountrySelect = (defaultValue?: Option[], placeholder?: string, size?: "sm" | "md" | "lg") => {
    const { data } = useGetCountriesQuery();
    const [value, setValue] = useState<Option[]>(defaultValue || []);

    const options: Option[] = data?.map(({ id, name }) => ({ label: name, value: String(id) })) || [];

    const CountrySelect = (
        <MultiSelect
            _placeholder={{ color: "gray.400" }}
            placeholder={placeholder || "Select country"}
            options={options}
            value={value}
            onChange={val => {
                // @ts-ignore
                setValue(val);
            }}
            size={size || "md"}
            single={false}
            selectionVisibleIn={SelectionVisibilityMode.Both}
            filterFn={(option, query) => {
                const searchValue = query as string;
                return option.filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()));
            }}
        />
    );

    return { CountrySelect, values: value.map(({ value }) => Number(value)) };
};
